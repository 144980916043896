import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_GET_COMPANIES, URL_COMPANIES } from "../../constants/url";
import { TOAST_TYPE } from "../../constants";
import EditFinancialResult from "../../components/FinancialReport/EditFinancialResult";
import { useLoader } from "../../context/Loader";
import { useToaster } from "../../context/Toaster";
import useAuthenticatedFetch from "../../hooks/useAuthenticatedFetch";

export default function EditCompanyFinancialSpreading() {
  const { company_id } = useParams();
  const navigate = useNavigate();
  const { setLoading } = useLoader()
  const { showToast } = useToaster()
  const [client, setClient] = useState(null)
  const authenticatedFetch = useAuthenticatedFetch()

  const fetchClient = async () => {
    setLoading(true)
    authenticatedFetch(`${API_GET_COMPANIES}/${company_id}`)
    .then(({ data }) => {
      setClient(data)
      return data
    }).catch(() => {
      showToast('Error when getting company data', TOAST_TYPE.ERROR)
      navigate(URL_COMPANIES)
    }).finally(() => {
      setLoading(false)
    })
  };

  useEffect(() => {
    if (company_id) {
      fetchClient();
    }
  }, [company_id]);

  const handleBack = () => {
    navigate(-1)
  }

  if (!client) {
    return null
  }

  return (
    <EditFinancialResult client={client} handleBack={handleBack} />
  )
  }
