export default function generateHighlightDiv ({ width, height, left = 0, top = 0, id }) {
  const highlightDiv = document.createElement("span");
  highlightDiv.style.position = "absolute";
  highlightDiv.id = id
  highlightDiv.className =
    "border-2 border-solid border-blue-secondary bg-blue-secondary";
  highlightDiv.style.padding = `6px`;
  highlightDiv.style.left = `${left - 4}px`;
  highlightDiv.style.top = `${top - 4}px`;
  highlightDiv.style.width = `${width + 12}px`;

  highlightDiv.style.height = `${height + 4}px`;
  highlightDiv.style.display = "none";

  return highlightDiv;
};