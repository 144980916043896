// function to process numbers who are surrounded by strings (ex: "(12345)" when indicating negative values")
// it accepts a function, pass the numbers only to that function
// it will then return a value with the numbers and the strings surrounding it
// ex: (12345) pass towards the formatter -> (12,345)
export default function processNumber(formatter, value) {
  if (!value) {
    return value;
  }

  const regex = /^([^\d]*)(\d+)([^\d]*)$/;
  const match = value.toString().match(regex);

  if (!match) {
    return value;
  }

  const [, beforeNumber, number, afterNumber] = match

  // Reconstruct the string with the extracted number, preserving leading and trailing non-numeric characters
  return beforeNumber + formatter(number) + afterNumber;
}
