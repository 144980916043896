import generateHighlightDiv from "./generateHighlightDiv";

export default function loadOcrIntoHighlight(parentElement, currentPageOcrRowData, data, currentDocumentType, ref) {
    let lastMatchedIndex = 0;
    const parentWidth = parentElement.clientWidth;
    const parentHeight = parentElement.clientHeight;

    const mappedRows = {}

    data.forEach(({ client_account_name, document_type }, dataIndex) => {
      if (document_type !== currentDocumentType) {
        return;
      }
      for (
        let i = lastMatchedIndex;
        i < currentPageOcrRowData.length;
        i++
      ) {
        const { constructedStr, minX, minY, maxX, maxY } =
          currentPageOcrRowData[i];

        const lowerClientAccountName = client_account_name
          .toLowerCase().replace(/[^a-zA-Z0-9]/g, "")
        const lowerConstructedStr = constructedStr.toLowerCase().replace(/[^a-zA-Z0-9]/g, "");

        if (!lowerConstructedStr || !lowerClientAccountName || mappedRows[i]) {
          continue
        }

        // client account name equals, means single line and found
        if (lowerConstructedStr.startsWith(lowerClientAccountName)) {
          const left = minX * parentWidth;
          const top = minY * parentHeight;
          const width = (maxX - minX) * parentWidth;
          const height = (maxY - minY) * parentHeight;
          const highlightDiv = generateHighlightDiv({
            width,
            height,
            left,
            top,
          });
          ref.current[dataIndex] = highlightDiv;
          parentElement.appendChild(highlightDiv);

          lastMatchedIndex = i;
          mappedRows[i] = true
          return;
        }

        // multi line client account name
        if (lowerClientAccountName.startsWith(lowerConstructedStr)) {
          let remainingCheckedStr = lowerClientAccountName
            .substring(lowerConstructedStr.length)
            .replace(/[^a-zA-Z0-9]/g, "");

          mappedRows[i] = true

          const multilineOcrData = [];
          for (let j = i + 1; j < currentPageOcrRowData.length; j++) {
            const { constructedStr } = currentPageOcrRowData[j];
            const lowerConstructedStr = constructedStr.toLowerCase().replace(/[^a-zA-Z0-9]/g, "");

            if (
              !(
                lowerConstructedStr.startsWith(remainingCheckedStr) ||
                remainingCheckedStr.startsWith(lowerConstructedStr)
              )
            ) {
              i = j - 1;
              break;
            }

            multilineOcrData.push(currentPageOcrRowData[j]);
            mappedRows[j] = true

            // last string
            if (lowerConstructedStr.startsWith(remainingCheckedStr)) {
              i = j;
              break;
            }
          }

          if (!multilineOcrData.length) {
            const left = minX * parentWidth;
            const top = minY * parentHeight;
            const width = (maxX - minX) * parentWidth;
            const height = (maxY - minY) * parentHeight;
            const highlightDiv = generateHighlightDiv({
              width,
              height,
              left,
              top,
            });
            ref.current[dataIndex] = highlightDiv;
            parentElement.appendChild(highlightDiv);

            lastMatchedIndex = i;
            return;
          }

          const { minX: multilineMinX, minY: multilineMinY } = multilineOcrData[0];
          const { maxX: multilineMaxX, maxY: multilineMaxY } =
            multilineOcrData[multilineOcrData.length - 1];

          const left = multilineMinX * parentWidth;
          const top = multilineMinY * parentHeight;
          const width = (multilineMaxX - multilineMinX) * parentWidth;
          const height = (multilineMaxY - multilineMinY) * parentHeight;
          const highlightDiv = generateHighlightDiv({
            width,
            height,
            left,
            top,
          });

          ref.current[dataIndex] = highlightDiv;
          parentElement.appendChild(highlightDiv);

          lastMatchedIndex = i;
          return
        }
      }

      // if nothing matches, then re-search the whole thing with a more lax approach
      for (
        let i = 0;
        i < currentPageOcrRowData.length;
        i++
      ) {
        const { constructedStr, minX, minY, maxX, maxY } =
          currentPageOcrRowData[i];

        const lowerClientAccountName = client_account_name
          .toLowerCase().replace(/[^\p{L}\p{N}\s]/gu, "")
        const lowerConstructedStr = constructedStr.toLowerCase().replace(/[^\p{L}\p{N}\s]/gu, "");


        if (!lowerConstructedStr || !lowerClientAccountName || mappedRows[i]) {
          continue
        }

        // client account name equals, means single line and found
        if (lowerConstructedStr.startsWith(lowerClientAccountName)) {
          const left = minX * parentWidth;
          const top = minY * parentHeight;
          const width = (maxX - minX) * parentWidth;
          const height = (maxY - minY) * parentHeight;
          const highlightDiv = generateHighlightDiv({
            width,
            height,
            left,
            top,
          });
          ref.current[dataIndex] = highlightDiv;
          parentElement.appendChild(highlightDiv);

          lastMatchedIndex = i;
          mappedRows[i] = true
          return;
        }

        // multi line client account name
        if (lowerClientAccountName.startsWith(lowerConstructedStr)) {
          let remainingCheckedStr = lowerClientAccountName
            .substring(lowerConstructedStr.length)
            .replace(/[^a-zA-Z0-9]/g, "");

          mappedRows[i] = true

          const multilineOcrData = [];
          for (let j = i + 1; j < currentPageOcrRowData.length; j++) {
            const { constructedStr } = currentPageOcrRowData[j];
            const lowerConstructedStr = constructedStr.toLowerCase().replace(/[^a-zA-Z0-9]/g, "");

            if (
              !(
                lowerConstructedStr.startsWith(remainingCheckedStr) ||
                remainingCheckedStr.startsWith(lowerConstructedStr)
              )
            ) {
              i = j - 1;
              break;
            }

            multilineOcrData.push(currentPageOcrRowData[j]);
            mappedRows[j] = true

            // last string
            if (lowerConstructedStr.startsWith(remainingCheckedStr)) {
              i = j;
              break;
            }
          }

          if (!multilineOcrData.length) {
            const left = minX * parentWidth;
            const top = minY * parentHeight;
            const width = (maxX - minX) * parentWidth;
            const height = (maxY - minY) * parentHeight;
            const highlightDiv = generateHighlightDiv({
              width,
              height,
              left,
              top,
            });
            ref.current[dataIndex] = highlightDiv;
            parentElement.appendChild(highlightDiv);

            lastMatchedIndex = i;
            return;
          }

          const { minX: multilineMinX, minY: multilineMinY } = multilineOcrData[0];
          const { maxX: multilineMaxX, maxY: multilineMaxY } =
            multilineOcrData[multilineOcrData.length - 1];

          const left = multilineMinX * parentWidth;
          const top = multilineMinY * parentHeight;
          const width = (multilineMaxX - multilineMinX) * parentWidth;
          const height = (multilineMaxY - multilineMinY) * parentHeight;
          const highlightDiv = generateHighlightDiv({
            width,
            height,
            left,
            top,
          });

          ref.current[dataIndex] = highlightDiv;
          parentElement.appendChild(highlightDiv);

          lastMatchedIndex = i;
          return
        }
      }
    });
}