import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import {
  URL_APPLICATION_CREATE,
  URL_APPLICATION_RESULT,
  URL_APPLICATION_VALIDATE,
  URL_CHANGE_PASSWORD,
  URL_CLIENTS_SUMMARY_PORTFOLIO,
  URL_COMPANIES,
  URL_CONTACT_US,
  URL_CONTACT_US_ERROR,
  URL_DASHBOARD,
  URL_EDIT_COMPANIES,
  URL_FORGOT_PASSWORD,
  URL_LOGIN,
  URL_MANAGE_MEMBERS,
  URL_RESET_PASSWORD,
  URL_TEAM_ACTIVITY,
  URL_USAGE_REPORT,
  URL_USER_PROFILE,
  URL_USERS,
} from "./constants/url";
import ApplicationProvider from "./context/Application";
import ManageClient from "./pages/Client";
import ContactUs from "./pages/ContactUs";
import DashboardPage from "./pages/Dashboard";
import EditCompanyFinancialSpreading from "./pages/EditCompanyFinancialSpreading";
import "./App.css";
import FinancialSpreadingResult from "./pages/FinancialSpreadingResult";
import FinancialSpreadingUploadPage from "./pages/FinancialSpreadingUpload";
import FinancialSpreadingUploadValidate from "./pages/FinancialSpreadingUpload/FinancialSpreadingUploadValidate";
import ForgotPassword from "./pages/ForgotPassword";
import Login from "./pages/Login";
import ManageAccounts from "./pages/ManageAccounts";
import ManageMember from "./pages/ManageMember";
import ManageUser from "./pages/ManagerUser";
import OverallPortfolio from "./pages/OverallPortfolio";
import Profile from "./pages/Profile";
import ProfileChangePassword from "./pages/Profile/ChangePassword";
import ResetPassword from "./pages/ResetPassword";
import TeamActivity from "./pages/TeamActivity";
import UsageReport from "./pages/UsageReport";
import NotFound from "./pages/NotFound";
import ProtectedRoute from "./components/ProtectedRoute";
import { ROLES } from "./constants";
import Provider from "./context";

export default function AppRoutes() {  
  return (
    <Router>
      <Provider>
      <Routes>
        {/* TODO: separate error pages, move not found to * route, 500 error to error boundary */}
        {/* <Route path={URL_ERROR_STATUS} element={<Error />} /> */}
        <Route exact path={URL_CONTACT_US} element={<ContactUs />} />
        <Route
          exact
          path={URL_CONTACT_US_ERROR}
          element={<ContactUs fromErrorPage={true} />}
        />
        <Route path={URL_LOGIN} element={<Login />} />
        <Route path={URL_FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={URL_RESET_PASSWORD} element={<ResetPassword />} />
        <Route element={<AuthenticatedRoute />}>
          <Route exact path={URL_DASHBOARD} element={<DashboardPage />} />
          <Route exact path={URL_USER_PROFILE} element={<Profile />} />
          <Route
            exact
            path={URL_CHANGE_PASSWORD}
            element={<ProfileChangePassword />}
          />
          <Route
            element={
              <ProtectedRoute
                allowedRoles={[ROLES.MANAGER, ROLES.UNDERWRITER]}
              />
            }
          >
            <Route exact path={URL_COMPANIES} element={<ManageAccounts />} />
            {/* <Route
              exact
              path={URL_FINANCIAL_SPREADING}
              element={<FinancialSpreading />}
            /> */}
            <Route element={<ApplicationProvider />}>
              <Route
                exact
                path={URL_APPLICATION_CREATE + "/:company_id?"}
                element={<FinancialSpreadingUploadPage />}
              />
              <Route
                exact
                path={URL_APPLICATION_VALIDATE + "/:application_id"}
                element={<FinancialSpreadingUploadValidate />}
              />
              <Route
                exact
                path={URL_APPLICATION_RESULT + "/:application_id"}
                element={<FinancialSpreadingResult />}
              />
              <Route
                exact
                path={URL_COMPANIES + "/:company_id"}
                element={<ManageClient />}
              />
              <Route
                exact
                path={URL_EDIT_COMPANIES}
                element={<EditCompanyFinancialSpreading />}
              />
            </Route>
            <Route exact path={URL_TEAM_ACTIVITY} element={<TeamActivity />} />
            <Route
              exact
              path={URL_CLIENTS_SUMMARY_PORTFOLIO}
              element={<OverallPortfolio />}
            />
          </Route>
          <Route element={<ProtectedRoute allowedRoles={[ROLES.MANAGER]} />}>
            <Route exact path={URL_MANAGE_MEMBERS} element={<ManageMember />} />
          </Route>
          <Route element={<ProtectedRoute allowedRoles={[ROLES.ADMIN]} />}>
            <Route exact path={URL_USAGE_REPORT} element={<UsageReport />} />
            <Route exact path={URL_USERS} element={<ManageUser />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      </Provider>
    </Router>
  );
}
