import { useState } from "react";
import Dropdowns from "../Dropdowns";
import { MONTHS_OPTIONS } from "../../constants";

export default function DatePicker({
  showTotalMonth = false,
  showTypeOfAccount = false,
  startingYear = new Date().getFullYear(),
  startingMonth,
  onDateChange,
  minDate,
}) {
  const [selectedYear, setSelectedYear] = useState(startingMonth ? startingYear : undefined)
  const [year, setYear] = useState(startingYear);
  const [month, setMonth] = useState(startingMonth);


  let minYear, minMonth
  if (minDate) {
    const datedMinDate = new Date(minDate)
    if (!isNaN(datedMinDate.getTime())) {
      minYear = datedMinDate.getFullYear()
      minMonth = datedMinDate.getMonth()
    }
  }
  
  const isMinYear = minYear && year <= minYear
  const selectedMonthClass = "border-primary-2 bg-primary-100";
  const disabledClass = "bg-gray-300 text-gray-500 cursor-not-allowed hover:border-transparent hover:bg-gray-300"

  return (
    <div className="flex flex-col gap-[1.25rem] w-[188px]">
      <div className="flex justify-between items-center">
        {(!isMinYear) ? <button
          className="w-[32px] h-[32px] flex items-center justify-center rounded-[50%] border-[1px] border-control-light-hover hover:border-primary-2 hover:bg-primary-100"
          onClick={() => setYear((year) => parseInt(year) - 1)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6"
            height="8"
            viewBox="0 0 6 8"
            fill="none"
          >
            <path
              d="M2.06202 3.64683L1.70963 4L2.06202 4.35317L4.76258 7.05962L4.5293 7.29289L1.2364 4L4.5293 0.707107L4.76258 0.940385L2.06202 3.64683Z"
              fill="#525252"
              stroke="#525252"
            />
          </svg>
        </button> : <div className="w-[32px]" />}
        <span className="text-[1rem] leading-[1.5rem] font-[600] tracking-[-0.32px] text-neutral-strong">
          {year}
        </span>
        <button
          className="w-[32px] h-[32px] flex items-center justify-center rounded-[50%] border-[1px] border-control-light-hover rotate-180 hover:border-primary-2 hover:bg-primary-100"
          onClick={() => setYear((year) => parseInt(year) + 1)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6"
            height="8"
            viewBox="0 0 6 8"
            fill="none"
          >
            <path
              d="M2.06202 3.64683L1.70963 4L2.06202 4.35317L4.76258 7.05962L4.5293 7.29289L1.2364 4L4.5293 0.707107L4.76258 0.940385L2.06202 3.64683Z"
              fill="#525252"
              stroke="#525252"
            />
          </svg>
        </button>
      </div>
      <div className="grid grid-cols-3 grid-flow-row gap-[4px]">
        {MONTHS_OPTIONS.map(({ value, label }) => {
          const isMinMonth = minMonth && isMinYear && value < minMonth + 1
          return (
            <button
              key={value}
              className={`!border-[1px] border-transparent hover:border-primary-2 hover:bg-primary-100 text-center p-[8px] rounded-[8px] bg-black-alpha-100 font-[600] leading-[1rem] tracking-[-0.24px] text-primary-strong text-[0.75rem] ${
                month === value && year === selectedYear && selectedMonthClass
              }
                ${isMinMonth && disabledClass}
                `}
              onClick={() => {
                if (isMinMonth) {
                  return
                }
                setMonth(value);
                setSelectedYear(year);
                onDateChange?.(value, year);
              }}
            >
              {label}
            </button>
          );
        })}
      </div>
      {showTotalMonth && (
        <Dropdowns
          label="Select number of month"
          defaultValue="12"
          inputClassName="!text-[0.875rem] !leading-[1.25rem] !py-[0.5rem] !px-[1rem]"
          className=""
          data={[
            { label: "1" },
            { label: "2" },
            { label: "3" },
            { label: "4" },
            { label: "5" },
            { label: "6" },
            { label: "7" },
            { label: "8" },
            { label: "9" },
            { label: "10" },
            { label: "11" },
            { label: "12" },
          ]}
        />
      )}
      {showTypeOfAccount && (
        <Dropdowns
          label="Type of accounts"
          defaultValue="Audited"
          inputClassName="!text-[0.875rem] !leading-[1.25rem] !py-[0.5rem] !px-[1rem]"
          className=""
          data={[{ label: "Audited" }, { label: "Unaudited" }]}
        />
      )}
    </div>
  );
}
