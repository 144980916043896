import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import backIcon from "../../assets/icons/icon_back.svg";
import saveSuccessImg from "../../assets/images/save_success.png";
import {
  ALL_DOCUMENT_TYPES,
  DOCUMENT_TYPE_ENUM,
  DOCUMENT_TYPE_PROPERTY_GENERATED,
  DOCUMENT_TYPE_UPLOADED,
  TOAST_TYPE,
  UNIT_OPTIONS,
} from "../../constants";
import {
  API_GET_COMPANIES,
  URL_DASHBOARD,
  URL_EDIT_COMPANIES,
} from "../../constants/url";
import { useApplicationContext } from "../../context/Application";
import { useLoader } from "../../context/Loader";
import { useToaster } from "../../context/Toaster";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  financialSpreadingResultColumns,
  generatedColumns,
} from "../../pages/FinancialSpreadingResult/columns";
import downloadBlob from "../../utils/common/downloadBlob";
import generateWorkbook from "../../utils/financialSpreading/generateWorkbook";
import aggregateData from "../../utils/formula/aggregateData";
import calculateGeneratedDocuments from "../../utils/formula/calculateGeneratedDocuments";
import calculateUploadedDocuments from "../../utils/formula/calculateUploadedDocuments";
import sortPeriodString from "../../utils/formula/sortPeriodString";
import Dropdowns from "../Dropdowns";
import Modal from "../Modal";
import Tabs from "../Tabs";
import TextInput from "../TextInput";
import FinancialReportTableView from "./FinancialReportTableView";
import useAuthenticatedFetch from "../../hooks/useAuthenticatedFetch";
import { PERFORMANCE_CLASSIFICATION_OPTIONS } from "../../constants/options";
import TextDatePicker from "../TextDatePicker";

ViewFinancialResult.propTypes = {
  viewResult: PropTypes.bool,
  data: PropTypes.any,
  handleBack: PropTypes.func,
};

export const FORM_FIELDS = {
  group_name: "group_name",
  company_name: "company_name",
  doc_currency: "doc_currency",
  out_currency: "out_currency",
  exchange_rate: "exchange_rate",
  unit: "unit",
  sort_by: "sort_by",
  performance: 'performance',
  next_review_date: 'next_review_date',
};

const MODAL_TYPE = {
  SAVE: "SAVE",
  SAVE_AND_DOWNLOAD: "SAVE_AND_DOWNLOAD",
};

function ViewFinancialResult({
  viewResult = false,
  client,
  handleBack,
  fetchClient,
}) {
  const { data = [], metadata } = client;
  const aggregatedData = Object.values(aggregateData(data));
  const { company_id } = useParams();
  const { showToast } = useToaster();
  const { setLoading } = useLoader();
  const { width } = useWindowDimensions();
  const [decimalPlace, setDecimalPlace] = useState(2);
  const authenticatedFetch = useAuthenticatedFetch()

  const increaseDecimalPlace = () =>
    setDecimalPlace((decimalPlace) => decimalPlace + 1);
  const decreaseDecimalPlace = () =>
    setDecimalPlace((decimalPlace) => Math.max(decimalPlace - 1, 0));

  const [formData, setFormData] = useState({
    [FORM_FIELDS.out_currency]: client.out_currency,
    [FORM_FIELDS.exchange_rate]: parseFloat(client.exchange_rate).toFixed(2),
    [FORM_FIELDS.unit]: client.unit,
    [FORM_FIELDS.sort_by]: client.sort_by,
    [FORM_FIELDS.group_name]: client.group_name,
    [FORM_FIELDS.company_name]: client.company_name,
    [FORM_FIELDS.doc_currency]: client.doc_currency,
    [FORM_FIELDS.performance]: client.performance,
    [FORM_FIELDS.next_review_date]: client.next_review_date,
  });

  const handleSaveData = async () => {
    setLoading(true);
    const saveClientApi = `${API_GET_COMPANIES}/${company_id}`;
    authenticatedFetch(saveClientApi, {
      data: {
        ...client,
        ...formData,
      },
      withCredentials: true,
      method: 'PUT',
    })
      .catch((err) => {
        showToast(err.message, TOAST_TYPE.ERROR);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (key, value) => {
    setFormData((currentFormData) => {
      return {
        ...currentFormData,
        [key]: value,
      };
    });
  };

  const handleInputChange = (e) => {
    handleChange(e.target.name, e.target.value);
  };

  const handleDropdownChange = (value, name) => {
    handleChange(name, value);
  };

  const handleCurrencyChange = (value, name) => {
    handleChange(name, value);
    handleChange(FORM_FIELDS.exchange_rate, "");
  };

  const isSameCurrency =
    formData[FORM_FIELDS.doc_currency] &&
    formData[FORM_FIELDS.out_currency] &&
    formData[FORM_FIELDS.doc_currency] === formData[FORM_FIELDS.out_currency];

  useEffect(() => {
    if (isSameCurrency) {
      handleChange(FORM_FIELDS.exchange_rate, 1);
    }
  }, [isSameCurrency]);

  const navigate = useNavigate();
  const { currencyOptions } = useApplicationContext();
  const [hiddenPeriods, setHiddenPeriods] = useState([]);

  const setHiddenPeriodByIndex = (index, checked) => {
    setHiddenPeriods((currentHiddenPeriods) => {
      const newHiddenPeriods = [...currentHiddenPeriods];

      // hidden means not checked
      newHiddenPeriods[index] = !checked;

      return newHiddenPeriods;
    });
  };

  const [activeTab, setActiveTab] = useState(DOCUMENT_TYPE_UPLOADED[0].key);
  const currentDocumentType = activeTab;
  const currentDocumentAttribute = ALL_DOCUMENT_TYPES.find(
    ({ key, formula_type = key }) => formula_type === currentDocumentType
  );

  const getDocumentMetadata = (documentType) => {
    let documentMetadata = metadata[documentType] || {};
    const currentDocumentAttribute = ALL_DOCUMENT_TYPES.find(
      ({ key, formula_type: current_formula_type = key }) =>
        current_formula_type === documentType
    );

    // generated data doesn't have metadata, so we use income statement or cash flow statement as default
    if (currentDocumentAttribute.type === DOCUMENT_TYPE_PROPERTY_GENERATED) {
      documentMetadata =
        metadata[DOCUMENT_TYPE_ENUM.INCOME_STATEMENT] ||
        metadata[DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT];
    }

    return documentMetadata;
  };

  const getShownPeriodsByDocumentType = (documentType) => {
    const documentMetadata = getDocumentMetadata(documentType);
    const { fiscal_period_order = [] } = documentMetadata;
    return fiscal_period_order.filter((_, index) => {
      return !hiddenPeriods[index];
    });
  };

  const { fiscal_period_order = [] } = getDocumentMetadata(currentDocumentType);

  const displayedFiscalPeriodOrder = sortPeriodString(
    getShownPeriodsByDocumentType(currentDocumentType),
    formData[FORM_FIELDS.sort_by]
  );

  const [showSaveModal, setShowSaveModal] = useState({
    [MODAL_TYPE.SAVE]: false,
    [MODAL_TYPE.SAVE_AND_DOWNLOAD]: false,
  });

  const toggleShowSaveModal = async (type) => {
    return handleSaveData().then(() => {
      setShowSaveModal((prev) => ({
        ...prev,
        [type]: true,
      }));
      return fetchClient();
    });
  };

  const calculatedUploadedDocuments = calculateUploadedDocuments(
    metadata,
    aggregatedData
  );

  const calculateGeneratedDocumentsFormula = () => {
    const uploadedDocumentsData = [
      ...aggregatedData,
      ...calculatedUploadedDocuments,
    ];
    const generatedDocumentsData = calculateGeneratedDocuments(
      uploadedDocumentsData,
      formData[FORM_FIELDS.exchange_rate]
    );

    const allData = [...uploadedDocumentsData, ...generatedDocumentsData];

    allData.sort((a, b) =>
      a.account_code.localeCompare(b.account_code, undefined, {
        numeric: true,
      })
    );
    return allData;
  };

  const combinedDocuments = calculateGeneratedDocumentsFormula();

  const resultTable = financialSpreadingResultColumns(
    displayedFiscalPeriodOrder,
    width,
    [
      DOCUMENT_TYPE_ENUM.INCOME_STATEMENT,
      DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT,
    ].includes(activeTab)
  );

  const generatedTable = generatedColumns(displayedFiscalPeriodOrder, width);

  const shownData = combinedDocuments.filter(
    ({ document_type }) => document_type === currentDocumentType
  );

  const handleDownloadData = async () => {
    toggleShowSaveModal(MODAL_TYPE.SAVE_AND_DOWNLOAD).then(async () => {
      const workbook = generateWorkbook({
        group_name: formData.group_name,
        company_name: formData.company_name,
        doc_currency: formData.doc_currency,
        out_currency: formData.out_currency,
        exchange_rate: formData.exchange_rate,
        unit: formData.unit,
        data: combinedDocuments,
        decimalPlace,
        fiscalPeriodOrderMap: ALL_DOCUMENT_TYPES.reduce(
          (currentFiscalPeriodOrderMap, currentDocumentAttribute) => {
            const { key, formula_type = key } = currentDocumentAttribute;

            const displayedFiscalPeriodOrder = sortPeriodString(
              getShownPeriodsByDocumentType(formula_type),
              formData[FORM_FIELDS.sort_by]
            );

            return {
              ...currentFiscalPeriodOrderMap,
              [formula_type]: displayedFiscalPeriodOrder,
            };
          },
          {}
        ),
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      downloadBlob(
        blob,
        `${formData.group_name}_${
          formData.company_name
        }_${new Date().getTime()}.xlsx`
      );
    });
  };

  const handleGoToEditCompanyData = () => {
    navigate(URL_EDIT_COMPANIES.replace(':company_id', company_id));
  };

  const isFormInvalid = Object.values(FORM_FIELDS).some((key) => !formData[key])

  return (
    <div
      className={`w-full max-w-default sm:px-[48px] 2xl:px-[64px] relative min-h-[calc(100vh-70px)] flex flex-col`}
    >
      <div className="py-[2.5rem] flex-col gap-[1rem]">
        <div className="pb-[1rem] flex justify-between">
          <div className="flex gap-[1rem] flex-1 items-center">
            <img
              src={backIcon}
              alt="back-button"
              className="cursor-pointer"
              onClick={handleBack}
            />
            <span className="text-gradient-aurora-blue text-[2rem] font-[700] leading-[2.5rem] tracking-[-0.96px]">
              Financial Spreading Result
            </span>
          </div>
          <div className="flex gap-[1rem]">
            <button
              className="!h-[2rem] !py-[0.25rem] !px-[0.5rem] default border-[1px] border-neutral-medium font-[600] leading-[1.5rem] tracking-[-0.48px] text-[0.875rem] flex justify-center items-center"
              onClick={handleGoToEditCompanyData}
            >
              Edit Financial
            </button>
            <button
              className="w-[4.5rem] !h-[2rem] !py-[0.25rem] !px-[0.5rem]  primary border-[1px] font-[600] leading-[1.5rem] tracking-[-0.48px] text-[0.875rem] flex justify-center items-center text-white"
              onClick={() => toggleShowSaveModal(MODAL_TYPE.SAVE)}
              disabled={isFormInvalid}
            >
              Save
            </button>
            <button
              className="w-[8rem] !h-[2rem] !py-[0.25rem] !px-[0.5rem] primary font-[600] leading-[1.5rem] tracking-[-0.48px] text-[0.875rem] text-white p-[0.75rem] flex justify-center items-center"
              onClick={handleDownloadData}
              disabled={isFormInvalid}
            >
              Save & Download
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center gap-[2rem] w-full">
        <div className="flex flex-col p-[2rem] gap-[1.5rem] bg-white max-w-[1186px] w-full rounded-[20px]">
          <div className="flex gap-[1.5rem] w-full">
            <TextInput
              id={FORM_FIELDS.group_name}
              label="Group Name"
              className="flex-1"
              value={formData[FORM_FIELDS.group_name]}
              onChange={handleInputChange}
            />
            <TextInput
              id={FORM_FIELDS.company_name}
              label="Company Name"
              className="flex-1"
              value={formData[FORM_FIELDS.company_name]}
              onChange={handleInputChange}
            />
            <Dropdowns
              id={FORM_FIELDS.performance}
              label="Performance Classification​"
              className="flex-1"
              value={formData[FORM_FIELDS.performance]}
              data={PERFORMANCE_CLASSIFICATION_OPTIONS}
              onChange={handleDropdownChange}
              defaultValue={client.performance}
            />
            <TextDatePicker
                label="Next Review Date​"
                className="flex-1"
                onChange={handleDropdownChange}
                id={FORM_FIELDS.next_review_date}
                value={formData[FORM_FIELDS.next_review_date]}
                minDate={new Date()}
                dateFormat="MMM YYYY"
              />
          </div>
          <div className="flex gap-[1.5rem] w-full">
            <Dropdowns
              label="Document Currency"
              className="flex-1"
              defaultValue={client.doc_currency}
              data={currencyOptions}
              id={FORM_FIELDS.doc_currency}
              onChange={handleCurrencyChange}
              value={formData[FORM_FIELDS.doc_currency]}
            />
            <Dropdowns
              label="Output Currency"
              className="flex-1"
              defaultValue={client.out_currency}
              value={formData[FORM_FIELDS.out_currency]}
              data={currencyOptions}
              onChange={handleCurrencyChange}
              id={FORM_FIELDS.out_currency}
            />
            <TextInput
              label="Forex Rate"
              className="flex-1"
              value={formData[FORM_FIELDS.exchange_rate]}
              onChange={handleInputChange}
              id={FORM_FIELDS.exchange_rate}
              placeholder={
                formData[FORM_FIELDS.out_currency]
                  ? `1 ${formData[FORM_FIELDS.out_currency]} to ${
                    formData[FORM_FIELDS.doc_currency]
                    }`
                  : ""
              }
            />
            <Dropdowns
              label="Unit"
              className="flex-1"
              defaultValue={client.unit}
              value={formData[FORM_FIELDS.unit]}
              data={UNIT_OPTIONS}
              onChange={handleDropdownChange}
              id={FORM_FIELDS.unit}
            />
          </div>
        </div>
        <div className="max-w-[1186px] w-full flex flex-col gap-[1.5rem]">
          <Tabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabsLabel={ALL_DOCUMENT_TYPES}
          />
          <FinancialReportTableView
            data={shownData}
            currentDocumentAttribute={currentDocumentAttribute}
            client={client}
            formData={formData}
            handleDropdownChange={handleDropdownChange}
            setHiddenPeriods={setHiddenPeriods}
            fiscal_period_order={fiscal_period_order}
            setHiddenPeriodByIndex={setHiddenPeriodByIndex}
            hiddenPeriods={hiddenPeriods}
            resultTable={resultTable}
            currentDocumentType={currentDocumentType}
            generatedTable={generatedTable}
            decimalPlace={decimalPlace}
            increaseDecimalPlace={increaseDecimalPlace}
            decreaseDecimalPlace={decreaseDecimalPlace}
          />
        </div>
      </div>
      {/* success modal */}
      {showSaveModal[MODAL_TYPE.SAVE] && (
        <Modal
          title={"Saved Successfully!"}
          img={saveSuccessImg}
          confirmText="Back to Home"
          onConfirm={() => navigate(URL_DASHBOARD)}
          onClose={() =>
            setShowSaveModal((prev) => ({
              ...prev,
              [MODAL_TYPE.SAVE]: false,
            }))
          }
        />
      )}
      {showSaveModal[MODAL_TYPE.SAVE_AND_DOWNLOAD] && (
        <Modal
          title={"Saved and Downloaded  Successfully!"}
          img={saveSuccessImg}
          confirmText="Back to Home"
          onConfirm={() => navigate(URL_DASHBOARD)}
          onClose={() =>
            setShowSaveModal((prev) => ({
              ...prev,
              [MODAL_TYPE.SAVE_AND_DOWNLOAD]: false,
            }))
          }
        />
      )}
      {/* failed modal */}
      {/* {showSaveModal && <Modal
        title={"Saved failed!"}
        img={saveFailedImg}
        confirmText="Try a gain"
        titleClassname="!text-[#831919]"
        onClose={toggleShowSaveModal}
        onConfirm={toggleShowSaveModal}
      />} */}
    </div>
  );
}

export default ViewFinancialResult;
