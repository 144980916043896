import { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowDropdownIcon } from "../../assets/icons/arrow_drop_down.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/icon_info.svg";
import DatePicker from "../DatePicker";
import dayjs from "dayjs";

export default function TextDatePicker({
  label,
  className = "",
  inputClassName = "",
  placeholder = "",
  defaultValue = "",
  labelClassName = "",
  onChange,
  allowCustomText = false,
  disabled = false,
  required = false,
  isSubmit = false,
  popoverClassname = "",
  popoverDataClassname = "",
  readOnly = false,
  value,
  dateFormat,
  ...props
}) {
  const { id, name = id } = props;

  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  let selectedMonth, selectedYear
  let inputValue = value

  if (value) {
      const dateValue = dayjs(value)

      if (dateValue.isValid()) {
        inputValue = dateValue.toDate().toDateString()
        if (dateFormat) {
          inputValue = dateValue.format(dateFormat)
        } 
        selectedMonth = dateValue.month() + 1
        selectedYear = dateValue.year()
      }
  }

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  return (
    <div
      className={`relative inline-block text-left ${className} w-full`}
      ref={dropdownRef}
    >
      <label htmlFor="menu-button" className={`${labelClassName} ${disabled && 'text-[#D1D1D1]'}`}>
        {label}
      </label>
      <div
        className={`relative flex ${!disabled && 'cursor-pointer'}`}
        onClick={() => !disabled && setOpen((prev) => !prev)}
      >
        <input
          name={name}
          ref={inputRef}
          id="menu-button"
          autoComplete="off"
          aria-expanded={open}
          aria-haspopup="true"
          placeholder={placeholder}
          disabled={disabled}
          required={required}
          className={`text-[#121212] font-[600] text-[0.875rem] leading-[1.5rem] tracking-[-0.32px] placeholder:font-[400] ${inputClassName} ${
            required && isSubmit && !props?.value
              ? "!border-b-[#F65737] !border-b-[1.5px]"
              : ""
          }`}
          readOnly
          value={inputValue}
        />
        <span
          className={`absolute right-[0.75rem] top-[50%] translate-y-[-50%] ${
            open ? "rotate-180" : ""
          }`}
        >
          <ArrowDropdownIcon color="#121212" />
        </span>
      </div>
      {isSubmit && required && !props?.value && (
        <div className="flex items-center gap-[4px] flex-grow flex-shrink-0 basis-0 mt-[4px] absolute">
          <InfoIcon color="#F65737" />
          <span className="text-[#F65737] text-[0.75rem] leading-[1rem]">
            Please fill up this field
          </span>
        </div>
      )}
      {open && (
        <div
          className={`absolute z-50 mt-2 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${popoverClassname}`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div
            className={`p-[0.5rem] max-h-[200px] overflow-y-auto ${popoverDataClassname}`}
            role="none"
          >
            <DatePicker
              {...props}
              onDateChange={(month, year) => {
                const monthStr = month < 10 ? `0${month}` : month
                const dateStr = `${year}-${monthStr}-01`
                const date = new Date(dateStr);
                const dateLabel = date.toDateString()
                inputRef.current.value = dateLabel;

                onChange?.(dateStr, name);
                setOpen(false)
              }}
              startingMonth={selectedMonth}
              startingYear={selectedYear}
            />
          </div>
        </div>
      )}
    </div>
  );
}
